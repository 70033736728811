<template>
    <div class='footer'>
        <div class="w1200">
            <p>trtst.com</p>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data () {
        // 这里存放数据
        return {
        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 方法集合
    methods: {
    }
}
</script>
<style scoped lang="less">
.footer {
    line-height: 40px;
    background: #06060b;
    color: #60606d;
}
</style>
